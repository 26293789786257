<template>
  <div class="mt-5">
    <template v-if="isImported">
      <v-row>
        <v-col cols="2">
          <v-card>
            <v-card-title class="v-card-title-custom-size">
              <span>Bollati</span>
            </v-card-title>
            <v-card-text>
              <h3>{{ countBollato || '0' }}</h3>
            </v-card-text>
            </v-card>
        </v-col>
          <v-col cols="3">
            <v-card>
            <v-card-title class="v-card-title-custom-size">
              <span>Totale</span>
            </v-card-title>
            <v-card-text>
              <h3>€ {{ totaleBollato || '0.00' }}</h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="2">
          <v-card :color="quadraturaDifferenzaOk && !isNotReconciliated ? 'green' : '#d3d3d3'">
            <v-card-title class="v-card-title-custom-size">
              <span>Differenza</span>
            </v-card-title>
            <v-card-text>
              <h3>€ {{ differenzaMovimentiBollato ? differenzaMovimentiBollato : '0.00' }}</h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="2">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          <span>Movimenti</span>
          </v-card-title>
            <v-card-text>
          <h3>{{ countMovimenti || '0' }}</h3>
          </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          Totale Avere
          </v-card-title>
            <v-card-text>
          <h3>€ {{ totaleMovimenti || '0.00' }}</h3>
          </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="!isNotReconciliated">
      <v-row>
        <v-col cols="2">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          <span>Scarto Bollati</span>
          </v-card-title>
          <v-card-text>
          <h3>{{ countScartiBollato || '0' }}</h3>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          <span>Totale Scarto Bollati</span>
            </v-card-title>
          <v-card-text>
          <h3>€ {{ totaleScartiBollato || '0.00' }}</h3>
          </v-card-text>
        </v-card>
        </v-col>

        <v-col cols="2">
          <v-card color="#d3d3d3" >
          <v-card-title class="v-card-title-custom-size">
          <span>Differenza Scarti</span>
          </v-card-title>
          <v-card-text>
          <h3>€ {{ differenzaScartiMovimentiScartiBollato ? differenzaScartiMovimentiScartiBollato : '0.00' }}</h3>
          </v-card-text>
        </v-card>
        </v-col>

        <v-col cols="2">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          <span>Scarto Movimenti</span>
          </v-card-title>
          <v-card-text>
          <h3>{{ countScartiMovimenti || '0' }}</h3>
          </v-card-text>
        </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
          <v-card-title class="v-card-title-custom-size">
          <span>Totale Scarto Movimenti</span>
          </v-card-title>
          <v-card-text>
          <h3>€ {{ totaleScartiMovimenti || '0.00' }}</h3>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BollatoMovimentiStats',
  props: {
    riconciliaDone: {
      type: Boolean,
      default: false
    },
    checkbox: {
      type:Object,
      default: () => ({
        sumBollati: 0,
        sumBollatiCount: 0,
        sumMovimenti: 0,
        sumMovimentiCount: 0,
        sumScartiBollati: 0,
        sumScartiBollatiCount: 0,
        sumScartiMovimenti: 0,
        sumScartiMovimentiCount: 0,
      }),
    },
  },
  computed: {
    ...mapGetters('bollato', {
      statsBollato: 'getStats',
    }),
    ...mapGetters('movimenti', {
      statsMovimenti: 'getStats',
    }),
    ...mapGetters('bollatoScarti', {
      statsBollatoScarti: 'getStats',
    }),
    ...mapGetters('movimentiScarti', {
      statsMovimentiScarti: 'getStats',
    }),

    isNotReconciliated() {
      return !this.riconciliaDone && parseInt(this.statsBollato?.total_check_riferimento) === 0
    },
    isImported() {
      if((this.statsBollato?.count && this.statsBollato.count > 0 )
        || (this.statsMovimenti?.count && this.statsMovimenti.count > 0)) {
        return true
      }
      return false
    },
    countBollato() {
      return parseFloat(this.statsBollato.count) - parseFloat(this.checkbox.sumBollatiCount)
    },
    countMovimenti() {
      return parseFloat(this.statsMovimenti.count) - parseFloat(this.checkbox.sumMovimentiCount)
    },
    countScartiBollato() {
      return parseFloat(this.statsBollatoScarti.count) - parseFloat(this.checkbox.sumScartiBollatiCount)
    },
    countScartiMovimenti() {
      return parseFloat(this.statsMovimentiScarti.count) - parseFloat(this.checkbox.sumScartiMovimentiCount)
    },
    totaleBollato() {
      return (parseFloat(this.statsBollato.total) - parseFloat(this.checkbox.sumBollati)).toFixed(2)
    },
    totaleMovimenti() {
      return (parseFloat(this.statsMovimenti.total) - parseFloat(this.checkbox.sumMovimenti)).toFixed(2)
    },
    totaleScartiBollato() {
      return (parseFloat(this.statsBollatoScarti.total) - parseFloat(this.checkbox.sumScartiBollati)).toFixed(2)
    },
    totaleScartiMovimenti() {
      return (parseFloat(this.statsMovimentiScarti.total) - parseFloat(this.checkbox.sumScartiMovimenti)).toFixed(2)
    },
    differenzaMovimentiBollato() {
      if (!this.statsMovimentiScarti?.total)
        return 0

      if (!this.statsBollatoScarti?.total)
        return 0

      return (
        parseFloat(this.statsMovimenti.total) - parseFloat(this.checkbox.sumMovimenti) -
        parseFloat(this.statsBollato.total) + parseFloat(this.checkbox.sumBollati)

      ).toFixed(2)
    },
    differenzaScartiMovimentiScartiBollato() {
      if (!this.statsMovimenti?.total)
        return 0

      if (!this.statsBollato?.total)
        return 0
      
      return (
        parseFloat(this.statsMovimentiScarti.total) - parseFloat(this.checkbox.sumScartiMovimenti) -
        parseFloat(this.statsBollatoScarti.total) + parseFloat(this.checkbox.sumScartiBollati)
        
      ).toFixed(2)
    },
    quadraturaDifferenzaOk() {
      return this.differenzaMovimentiBollato === 0;
    }
  },
}
</script>

<style lang="scss">
.v-card-title-custom-size {
  font-size: 1.1em;
}
</style>
