<template>
  <v-card flat>
    <BaseTable
      ref="table"
      v-model="selected"
      title="Movimenti"
      module="movimenti"
      :headers="headers"
      exportable
      show-select
      item-key="row_id"
      :elevation="0"
      :sortby="[]"
      :sort-desc="false"
      expandable
    >
      <template v-slot:item.row_id="{ item }">
        <v-chip label outlined class="font-weight-medium">
          {{ item.row_id }}
        </v-chip>
      </template>
      <template v-slot:item.riconciliato="{ item }">
        <v-chip v-if="item.riconciliato" color="green" label dark small>
          <v-icon small>mdi-check</v-icon>
        </v-chip>
        <v-chip v-else label outlined small>
          <v-icon small>mdi-close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item.importo_avere="{ item }">
        {{ item.importo_avere | number('0.00') }}
      </template>
      <template v-slot:item.bollatos="{ item }">
        <v-btn icon x-small @click.native="showModal(item)">
          <v-icon >mdi-magnify</v-icon>
        </v-btn>
        <v-chip
          v-for="b in item.bollatos"
          :key="b.row_id"
          label
          outlined
          small
        >
          {{ b.riferimento }}
        </v-chip>
      </template>
      <template v-slot:item.conto_corrente="{ item }">
        {{ item.conto_corrente ? "..." + item.conto_corrente.slice(-5) : "" }}
      </template>
    </BaseTable>
  </v-card>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
export default {
  name: 'TabMovimenti',
  components: { BaseTable },
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'row_id',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. causale',
        value: 'descrizione_causale',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 1',
        value: 'descrizione_aggiuntiva_1',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 2',
        value: 'descrizione_aggiuntiva_2',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 3',
        value: 'descrizione_aggiuntiva_3',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Importo Avere',
        value: 'importo_avere',
        cellClass: 'text-end'
      },
      {
        text: 'Riferimento Cliente',
        value: 'riferimento_cliente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Ordinante',
        value: 'ordinante',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Motivo Pagamento',
        value: 'motivo_pagamento',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Riconciliato',
        value: 'riconciliato',
      },
      {
        text: 'Bollato',
        value: 'bollatos',
        sortable: false,
      },
      {
        text: 'Conto Corrente',
        value: 'conto_corrente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Tipo movimento',
        value: 'tipo_movimento',
        filterable: true,
        filterType: 'text',
      },
    ],
    selected: [],
  }),
  /*
  watch: {
    selected: {
      handler(newVal) {
        const ctx = {
          newVal: newVal,
          tab: 'movimenti'
        }
        this.$emit('manage-checkbox', ctx)
      },
      immediate: true,
    },
  },
  */
  methods: {
    resetSelected() {
      this.selected = [];
    },
    showModal(item) {
      this.$emit('show-modal', {
        title: 'Bollati Associati',
        scope: 'bollato',
        items: item.bollatos,
      })
    },
  },
}
</script>

<style scoped></style>
