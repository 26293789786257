<template>
  <v-card flat>
    <BaseTable
      ref="table"
      v-model="selected"
      title="Scarti Bollato"
      module="bollatoScarti"
      :headers="headers"
      exportable
      show-select
      item-key="row_id"
      :elevation="0"
      :sortby="[]"
      :sort-desc="false"
      expandable
    >
      <template v-slot:item.riferimento="{ item }">
        <v-chip label outlined class="font-weight-medium">
          {{ item.riferimento | placeholder('-') }}
        </v-chip>
      </template>
      <template v-slot:item.data_bollato="{ item }">
        {{ item.data_bollato | momentOr('DD/MM/YYYY', '-') }}
      </template>

      <template v-slot:item.importo="{ item }">
        {{ item.importo | number('0.00') }}
      </template>
      <template v-slot:item.importi_cumulati="{ item }">
        <v-chip
          v-if="item.importi_cumulati"
          color="green"
          label
          dark
          outlined
          small
        >
          <v-icon small>mdi-check</v-icon>
        </v-chip>
      </template>
      <template v-slot:item.check_importo="{ item }">
        <v-chip v-if="item.check_importo" color="green" label dark outlined small>
          <v-icon small>mdi-check</v-icon>
        </v-chip>
      </template>
      <template v-slot:item.check_nome="{ item }">
        <v-chip v-if="item.check_nome" color="green" label dark outlined small>
          <v-icon small>mdi-check</v-icon>
        </v-chip>
      </template>
      <template v-slot:item.check_riferimento="{ item }">
        <v-chip v-if="item.check_riferimento" color="green" label dark outlined small>
          <v-icon small>mdi-check</v-icon>
        </v-chip>
      </template>
      <template v-slot:item.differenza_importo="{ item }">
        {{ item.differenza_importo | placeholder(0) | number('0.00') }}
      </template>
      <template v-slot:item.movimenti="{ item }">
        <v-btn icon x-small @click.native="showModal(item)">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-chip v-for="m in item.movimentis" :key="m.row_id" label outlined small>
          {{ m.row_id }}
        </v-chip>
      </template>
    </BaseTable>
  </v-card>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'

export default {
  name: 'TabScartiBollato',
  components: { BaseTable },
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'row_id',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Riferimento',
        value: 'riferimento',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Importo',
        value: 'importo',
        cellClass: 'text-end',
      },
      {
        text: 'Intestatario',
        value: 'intestatario',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Progressivo Bollato',
        value: 'progressivo_bollato',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Cliente',
        value: 'cliente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Movimenti',
        value: 'movimenti',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Importi Cumulati',
        value: 'importi_cumulati',
      },
      {
        text: 'Check Importo',
        value: 'check_importo',
      },
      {
        text: 'Check Nome',
        value: 'check_nome',
      },
      {
        text: 'Check Riferimento',
        value: 'check_riferimento',
      },
      {
        text: 'Diff. Importo',
        value: 'differenza_importo',
        cellClass: 'text-end'
      },
      {
        text: 'Tipo Bollato',
        value: 'tipo_bollato',
        filterable: true,
        filterType: 'text',
      },
      // {
      //   text: 'Inserito',
      //   value: 'created_at',
      // },
    ],
    selected: [],
  }),
  watch: {
    selected: {
      handler(newVal) {
        const ctx = {
          newVal: newVal,
          tab: 'scarti_bollato'
        }
        this.$emit('manage-checkbox', ctx)
      },
      immediate: true,
    },
  },
  methods: {
    resetSelected() {
      this.selected = [];
    },
    showModal(item) {
      this.$emit('show-modal', {
        title: 'Movimenti Associati',
        scope: 'movimenti',
        items: item.movimentis,
      })
    },
  },
}
</script>

<style scoped></style>
