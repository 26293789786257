<template>
  <v-data-table
    :headers="headers"
    :items="items">
    <!--scopes movimenti-->
    <template v-slot:item.row_id="{ item }">
      <v-chip label outlined class="font-weight-medium">
        {{ item.row_id }}
      </v-chip>
    </template>
    <template v-slot:item.riconciliato="{ item }">
      <v-chip v-if="item.riconciliato" color="green" label dark small>
        <v-icon small>mdi-check</v-icon>
      </v-chip>
      <v-chip v-else label outlined small>
        <v-icon small>mdi-close</v-icon>
      </v-chip>
    </template>
    <template v-slot:item.importo_avere="{ item }">
      {{ item.importo_avere | number('0.00') }}
    </template>
    <template v-slot:item.conto_corrente="{ item }">
      {{ item.conto_corrente ? "..." + item.conto_corrente.slice(-5) : "" }}
    </template>
    <!-- /scopes movimenti-->


    <!-- scopes bollato -->
    <template v-slot:item.row_id="{ item }">
      <v-chip label outlined class="font-weight-medium">
        {{ item.row_id }}
      </v-chip>
    </template>
    <template v-slot:item.riferimento="{ item }">
      <v-chip label outlined class="font-weight-medium">
        {{ item.riferimento | placeholder('-') }}
      </v-chip>
    </template>

    <template v-slot:item.data_bollato="{ item }">
      {{ item.data_bollato | momentOr('DD/MM/YYYY', '-') }}
    </template>

    <template v-slot:item.importo="{ item }">
      {{ item.importo | number('0.00') }}
    </template>
    <template v-slot:item.riconciliato="{ item }">
      <v-chip
        v-if="item.riconciliato"
        color="green"
        label
        dark
        small
      >
        <v-icon small>mdi-check</v-icon>
      </v-chip>

      <v-chip v-else label outlined small>
        <v-icon small>mdi-close</v-icon>
      </v-chip>
    </template>
    <template v-slot:item.check_importo="{ item }">
      <v-chip
        v-if="item.check_importo"
        color="green"
        label
        dark
        outlined
        small
      >
        <v-icon small>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:item.check_nome="{ item }">
      <v-chip v-if="item.check_nome" color="green" label dark outlined small>
        <v-icon small>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:item.check_riferimento="{ item }">
      <v-chip
        v-if="item.check_riferimento"
        color="green"
        label
        dark
        outlined
        small
      >
        <v-icon small>mdi-check</v-icon>
      </v-chip>
    </template>
    <template v-slot:item.differenza_importo="{ item }">
      {{ item.differenza_importo | placeholder(0) | number('0.00') }}
    </template>
    <!-- /scopes bollato -->
  </v-data-table>
</template>

<script>
const SCOPES = Object.freeze({
  BOLLATO: 'bollato',
  MOVIMENTI: 'movimenti',
})

export default {
  name: 'BollatoMovimentiDialogTable',
  props: {
    scope: {
      type: String,
      default: SCOPES.BOLLATO,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headersMovimenti: [
      {
        text: 'ROW ID',
        value: 'row_id',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. causale',
        value: 'descrizione_causale',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 1',
        value: 'descrizione_aggiuntiva_1',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 2',
        value: 'descrizione_aggiuntiva_2',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Desc. Aggiuntiva 3',
        value: 'descrizione_aggiuntiva_3',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Importo Avere',
        value: 'importo_avere',
      },
      {
        text: 'Riferimento Cliente',
        value: 'riferimento_cliente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Ordinante',
        value: 'ordinante',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Motivo Pagamento',
        value: 'motivo_pagamento',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Riconciliato',
        value: 'riconciliato',
        filterable: true,
        filterType: 'boolean',
      },
      {
        text: 'Conto Corrente',
        value: 'conto_corrente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Tipo movimento',
        value: 'tipo_movimento',
        filterable: true,
        filterType: 'text',
      },
    ],
    headersBollato: [
      {
        text: 'ROW ID',
        value: 'row_id',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Rif.',
        value: 'riferimento',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Importo',
        value: 'importo',
        filterable: false,
        filterType: 'text',
      },
      {
        text: 'Intestatario',
        value: 'intestatario',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Progressivo Bollato',
        value: 'progressivo_bollato',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Cliente',
        value: 'cliente',
        filterable: true,
        filterType: 'text',
      },
      {
        text: 'Data Bollato',
        value: 'data_bollato',
      },
      {
        text: 'Riconciliato',
        value: 'riconciliato',
        filterable: true,
        filterType: 'boolean',
      },
      {
        text: 'Importi Cumulati',
        value: 'importi_cumulati',
      },
      {
        text: 'Check Importo',
        value: 'check_importo',
        filterable: true,
        filterType: 'boolean',
      },
      {
        text: 'Check Nome',
        value: 'check_nome',
        filterable: true,
        filterType: 'boolean',
      },
      {
        text: 'Check Riferimento',
        value: 'check_riferimento',
        filterable: true,
        filterType: 'boolean',
      },
      {
        text: 'Diff. Importo',
        value: 'differenza_importo',
        alignValue: 'right',
      },
      {
        text: 'Tipo Bollato',
        value: 'tipo_bollato',
        filterable: true,
        filterType: 'text',
      },
    ],
  }),
  computed: {
    headers() {
      if (this.scope === SCOPES.MOVIMENTI) return this.headersMovimenti
      return this.headersBollato
    },
  },
}
</script>

<style scoped></style>
