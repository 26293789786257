var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.row_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(item.row_id)+" ")])]}},{key:"item.riconciliato",fn:function(ref){
var item = ref.item;
return [(item.riconciliato)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_c('v-chip',{attrs:{"label":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}},{key:"item.importo_avere",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.importo_avere,'0.00'))+" ")]}},{key:"item.conto_corrente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conto_corrente ? "..." + item.conto_corrente.slice(-5) : "")+" ")]}},{key:"item.riferimento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.riferimento,'-'))+" ")])]}},{key:"item.data_bollato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.data_bollato,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.importo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.importo,'0.00'))+" ")]}},{key:"item.check_importo",fn:function(ref){
var item = ref.item;
return [(item.check_importo)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.check_nome",fn:function(ref){
var item = ref.item;
return [(item.check_nome)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.check_riferimento",fn:function(ref){
var item = ref.item;
return [(item.check_riferimento)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.differenza_importo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(_vm._f("placeholder")(item.differenza_importo,0),'0.00'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }