<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Carica Bollato e Movimenti</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <BollatoMovimentiForm
          @reset="onReset"
          @refresh="refreshTables"
          @reconciliationDone="setReconciliateStat"
        />
      </v-card-text>
    </v-card>

    <BollatoMovimentiStats 
      :riconcilia-done="riconciliaDone"
      :checkbox="checkbox"
    />

    <v-card v-if="riconciliazione_id" class="mt-5">
      <v-card-text>
        <v-tabs background-color="transparent" :value="tabPosition">
          <v-tab>
            Bollato
          </v-tab>
          <v-tab>
            Movimenti
          </v-tab>
          <v-tab :disabled="isNotReconciliated">
            Scarti Bollato
          </v-tab>
          <v-tab :disabled="isNotReconciliated">
            Scarti Movimenti
          </v-tab>
          <v-tab-item>
            <TabBollato 
              ref="bollato" 
              @show-modal="onShowModal" 
              @manage-checkbox="onManageCheckbox" />
          </v-tab-item>
          <v-tab-item>
            <TabMovimenti 
              ref="movimenti" 
              @show-modal="onShowModal" 
              @manage-checkbox="onManageCheckbox" />
          </v-tab-item>
          <v-tab-item>
            <TabScartiBollato 
              ref="scartiBollato" 
              @show-modal="onShowModal" 
              @manage-checkbox="onManageCheckbox" />
          </v-tab-item>
          <v-tab-item>
            <TabScartiMovimenti
              ref="scartiMovimenti"
              @show-modal="onShowModal"
              @manage-checkbox="onManageCheckbox"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modal.show" :retain-focus="false">
      <v-card>
        <v-card-title>
          {{ modal.title }}
        </v-card-title>
        <v-card-text>
          <BollatoMovimentiDialogTable
            :scope="modal.scope"
            :items="modal.items"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'
import BollatoMovimentiStats from '@components/bollato_movimenti/BollatoMovimentiStats.vue'
import TabBollato from '@components/bollato_movimenti/TabBollato.vue'
import TabMovimenti from '@components/bollato_movimenti/TabMovimenti.vue'
import TabScartiBollato from '@components/bollato_movimenti/TabScartiBollato.vue'
import TabScartiMovimenti from '@components/bollato_movimenti/TabScartiMovimenti.vue'
import BollatoMovimentiDialogTable from '@components/bollato_movimenti/BollatoMovimentiDialogTable.vue'
import BollatoMovimentiForm from '@components/bollato_movimenti/BollatoMovimentiForm.vue'

const { mapFields: mapFieldsRiconciliazione } = createHelpers({
  getterType: 'riconciliazione/getCurrent',
  mutationType: 'riconciliazione/SET_CURRENT_FIELDS',
})

export default {
  name: 'BollatoMovimentiUpload',
  components: {
    BollatoMovimentiForm,
    BollatoMovimentiDialogTable,
    TabScartiMovimenti,
    TabScartiBollato,
    TabMovimenti,
    TabBollato,
    BollatoMovimentiStats,
  },
  page: {
    title: 'Carica Bollato e Movimenti',
  },
  data: () => ({
    tabPosition: 0,
    riconciliaDone: false,
    modal: {
      title: null,
      show: false,
      scope: null,
    },
    checkbox: {
      sumBollati: 0,
      sumBollatiCount:0,
      sumMovimenti: 0,
      sumMovimentiCount: 0,
      sumScartiBollati: 0,
      sumScartiBollatiCount: 0,
      sumScartiMovimenti: 0,
      sumScartiMovimentiCount: 0,
    }
  }),
  computed: {
    ...authComputed,
    ...mapGetters('bollato', {
      statsBollato: 'getStats',
    }),
    ...mapGetters('bollatoScarti', {
      statsBollatoScarti: 'getStats',
    }),
    ...mapFieldsRiconciliazione({
      id: 'id',
      user_id: 'user_id',
    }),
    ...mapState('riconciliazione', {
      riconciliazione_id: (state) => state.current.id,
    }),
    isNotReconciliated() {
      return (
        !this.riconciliaDone &&
        parseInt(this.statsBollato?.total_check_riferimento) === 0
      )
    },
  },
  watch: {
    riconciliazione_id: {
      handler(val) {
        if (!val) return
        this.setFilters()
        this.setScopes()
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.getIstanzaRiconciliazione()
  },
  // async mounted() {
  //   await
  // },
  methods: {
    ...mapActions('riconciliazione', {
      createIstanzaRiconciliazione: 'createRiconciliazione',
      updateIstanzaRiconciliazione: 'update',
      setCurrentIstanzaRiconciliazione: 'setCurrentBySearch',
      deleteIstanzaRiconciliazione: 'removeItem',
    }),
    ...mapMutations('riconciliazione', {
      setFiltersIstanzaRiconciliazione: 'SET_FILTERS',
      setScopesIstanzaRiconciliazione: 'SET_SCOPES',
      setFieldIstanzaRiconciliazione: 'SET_CURRENT_FIELD',
      resetCurrentIstanzaRiconciliazione: 'RESET_CURRENT',
    }),
    ...mapMutations('bollato', {
      setBollatoFilters: 'SET_FILTERS',
      setBollatoScopes: 'SET_SCOPES',
    }),
    ...mapMutations('bollatoScarti', {
      setBollatoScartiFilters: 'SET_FILTERS',
      setBollatoScartiScopes: 'SET_SCOPES',
    }),
    ...mapMutations('movimenti', {
      setMovimentiFilters: 'SET_FILTERS',
      setMovimentiScopes: 'SET_SCOPES',
    }),
    ...mapMutations('movimentiScarti', {
      setMovimentiScartiFilters: 'SET_FILTERS',
      setMovimentiScartiScopes: 'SET_SCOPES',
    }),

    setFilters() {
      this.setBollatoFilters({ riconciliazione_id: this.riconciliazione_id })
      this.setMovimentiFilters({ riconciliazione_id: this.riconciliazione_id })
      this.setBollatoScartiFilters({
        riconciliazione_id: this.riconciliazione_id,
      })
      this.setMovimentiScartiFilters({
        riconciliazione_id: this.riconciliazione_id,
      })
    },

    setScopes() {
      this.setBollatoScopes(['associationMovimenti'])
      this.setMovimentiScopes(['associationBollati'])
      this.setBollatoScartiScopes(['associationMovimenti'])
      this.setMovimentiScartiScopes(['associationBollati'])
    },

    async getIstanzaRiconciliazione() {
      this.setFiltersIstanzaRiconciliazione({
        user_id: this.currentUser.user.id,
      })
      this.setFieldIstanzaRiconciliazione({
        key: 'user_id',
        value: this.currentUser.user.id,
      })

      await this.setCurrentIstanzaRiconciliazione(['last_open'])

      if (!this.riconciliazione_id) {
        const data = await this.createIstanzaRiconciliazione()
        this.setFieldIstanzaRiconciliazione({ key: 'id', value: data.id })
      }
    },

    refreshTables() {
      console.info('refresh')
      this.$nextTick(() => {
        this.$refs.bollato?.$refs.table.refresh()
        this.$refs.movimenti?.$refs.table.refresh()
        this.$refs.scartiBollato?.$refs.table.refresh()
        this.$refs.scartiMovimenti?.$refs.table.refresh()
      })
    },

    async onReset() {
      this.$refs.bollato?.resetSelected()
      this.$refs.movimenti?.resetSelected()
      this.$refs.scartiBollato?.resetSelected()
      this.$refs.scartiMovimenti?.resetSelected()

      if (this.riconciliazione_id) {
        await this.deleteIstanzaRiconciliazione(this.riconciliazione_id)
        this.resetCurrentIstanzaRiconciliazione()
        this.setFieldIstanzaRiconciliazione({
          key: 'user_id',
          value: this.currentUser.user.id,
        })
        const data = await this.createIstanzaRiconciliazione()
        this.setFieldIstanzaRiconciliazione({ key: 'id', value: data.id })
        this.setFilters()
      }
    },

    setReconciliateStat(stat) {
      this.riconciliaDone = stat
    },

    onShowModal(data) {
      Object.assign(this.modal, data)
      this.modal.show = true
    },

    sumImportMovimenti(list) {
      return list.reduce(
        (importo, row) => importo + row.importo_avere,
        0,
      )
    },

    sumImportBollati(list) {
      return list.reduce(
        (importo, row) => importo + row.importo,
        0
      )
    },

    onManageCheckbox(ctx) {
      if (ctx.newVal) {
        if (ctx.tab === 'bollato') {
          this.checkbox.sumBollatiCount = ctx.newVal.length
          console.info('count line =' + this.checkbox.sumBollatiCount)
          this.checkbox.sumBollati = this.sumImportBollati(ctx.newVal)
        }
        if (ctx.tab === 'scarti_bollato') {
          this.checkbox.sumScartiBollatiCount = ctx.newVal.length
          this.checkbox.sumScartiBollati = this.sumImportBollati(ctx.newVal)
        }
        if (ctx.tab === 'movimenti') {
          this.checkbox.sumMovimentiCount = ctx.newVal.length
          this.checkbox.sumMovimenti = this.sumImportMovimenti(ctx.newVal)
        }
        if (ctx.tab === 'scarti_movimenti') {
          this.checkbox.sumScartiMovimentiCount = ctx.newVal.length
          this.checkbox.sumScartiMovimenti = this.sumImportMovimenti(ctx.newVal)
        }
      }
    },
  },
}
</script>
