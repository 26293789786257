var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('BaseTable',{ref:"table",attrs:{"title":"Scarti Bollato","module":"bollatoScarti","headers":_vm.headers,"exportable":"","show-select":"","item-key":"row_id","elevation":0,"sortby":[],"sort-desc":false,"expandable":""},scopedSlots:_vm._u([{key:"item.riferimento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.riferimento,'-'))+" ")])]}},{key:"item.data_bollato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.data_bollato,'DD/MM/YYYY', '-'))+" ")]}},{key:"item.importo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.importo,'0.00'))+" ")]}},{key:"item.importi_cumulati",fn:function(ref){
var item = ref.item;
return [(item.importi_cumulati)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.check_importo",fn:function(ref){
var item = ref.item;
return [(item.check_importo)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.check_nome",fn:function(ref){
var item = ref.item;
return [(item.check_nome)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.check_riferimento",fn:function(ref){
var item = ref.item;
return [(item.check_riferimento)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.differenza_importo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(_vm._f("placeholder")(item.differenza_importo,0),'0.00'))+" ")]}},{key:"item.movimenti",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},nativeOn:{"click":function($event){return _vm.showModal(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_vm._l((item.movimentis),function(m){return _c('v-chip',{key:m.row_id,attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(m.row_id)+" ")])})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }