var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('BaseTable',{ref:"table",attrs:{"title":"Movimenti","module":"movimenti","headers":_vm.headers,"exportable":"","show-select":"","item-key":"row_id","elevation":0,"sortby":[],"sort-desc":false,"expandable":""},scopedSlots:_vm._u([{key:"item.row_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"label":"","outlined":""}},[_vm._v(" "+_vm._s(item.row_id)+" ")])]}},{key:"item.riconciliato",fn:function(ref){
var item = ref.item;
return [(item.riconciliato)?_c('v-chip',{attrs:{"color":"green","label":"","dark":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1):_c('v-chip',{attrs:{"label":"","outlined":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}},{key:"item.importo_avere",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.importo_avere,'0.00'))+" ")]}},{key:"item.bollatos",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},nativeOn:{"click":function($event){return _vm.showModal(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_vm._l((item.bollatos),function(b){return _c('v-chip',{key:b.row_id,attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(b.riferimento)+" ")])})]}},{key:"item.conto_corrente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conto_corrente ? "..." + item.conto_corrente.slice(-5) : "")+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }