<template>
  <Form :disabled="isFormDisabled" @submit="onSubmit" @reset="onReset">
    <template>
      <v-row>
        <v-col>
          <v-file-input
            v-model="filesBollatoInAuto"
            label="Import Bollato InAuto"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
          <v-file-input
            v-model="filesBollatoDCSys"
            label="Import Bollato DCSys"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
          <v-file-input
            v-model="filesBollatoEMO"
            label="Import Bollato EMO"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
          <v-file-input
            v-model="filesBollatoRcInt"
            label="Import Bollato RcInt"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
        </v-col>
        <v-col>
          <v-file-input
            v-model="filesMovimentiInbiz"
            label="Import Movimenti InBiz"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
          <v-file-input
            v-model="filesMovimentiPoste"
            label="Import Movimenti Poste"
            multiple
            accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || text/csv"
            rule="size:100"
            show-size
            filled
            clearable
            dense
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn
        class="ml-3"
        depressed
        tile
        :color="uploadDone ? 'green' : 'primary'"
        :loading="doingRinconcilia"
        :disabled="doingRinconcilia || !isNotReconciliated"
        @click="clickBtnRinconcilia"
      >
        Riconcilia
      </v-btn>

      <v-btn
        class="ml-3"
        depressed
        tile
        :color="isNotReconciliated ? 'primary' : 'green'"
        :disabled="doingExportRiconciliazione || isNotReconciliated"
        @click="clickBtnExportRiconciliazione"
      >
        Export Complessivo
      </v-btn>
    </template>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
const { mapFields: mapFieldsBollato } = createHelpers({
  getterType: 'bollato/getCurrent',
  mutationType: 'bollato/SET_CURRENT_FIELDS',
})

const { mapFields: mapFieldsMovimenti } = createHelpers({
  getterType: 'movimenti/getCurrent',
  mutationType: 'movimenti/SET_CURRENT_FIELDS',
})

export default {
  name: 'BollatoMovimentiForm',
  components: { Form },
  data: () => ({
    uploadDone: false,
    riconciliaDone: false,
    doingRinconcilia: false,
    doingExportRiconciliazione: false,
    doingOnSubmit: false,
  }),
  computed: {
    ...mapFieldsBollato({
      typeBollato: 'type',
      fileBollato: 'file',
      filesBollatoInAuto: 'filesBollatoInAuto',
      filesBollatoDCSys: 'filesBollatoDCSys',
      filesBollatoEMO: 'filesBollatoEMO',
      filesBollatoRcInt: 'filesBollatoRcInt',
    }),
    ...mapFieldsMovimenti({
      typeMovimenti: 'type',
      fileMovimenti: 'file',
      filesMovimentiInbiz: 'filesMovimentiInbiz',
      filesMovimentiPoste: 'filesMovimentiPoste',
    }),

    ...mapGetters('bollato', {
      statsBollato: 'getStats',
    }),
    ...mapGetters('bollatoScarti', {
      statsBollatoScarti: 'getStats',
    }),
    ...mapState('riconciliazione', {
      riconciliazione_id: (state) => state.current.id,
    }),
    isNotReconciliated() {
      return !this.riconciliaDone && parseInt(this.statsBollato?.total_check_riferimento) === 0
    },
    isFormDisabled() {
      return (
        this.statsBollato?.total_check_riferimento !== "0" ||
        (
          !this.filesBollatoInAuto &&
          !this.filesBollatoDCSys &&
          !this.filesBollatoEMO &&
          !this.filesBollatoRcInt &&
          !this.filesMovimentiInbiz &&
          !this.filesMovimentiPoste
        )
      )
    },
  },
  watch: {
    riconciliazione_id: {
      handler(val) {
        if(!val) return
        this.setFilters()
        this.getStats()
      },
      immediate: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapActions('bollato', {
      createBulkBollato: 'createBulkWithType',
      download: 'download',
      getStatsBollato: 'getStats',
    }),
    ...mapActions('movimenti', {
      createBulkMovimenti: 'createBulkWithType',
      getStatsMovimenti: 'getStats',
    }),
    ...mapActions('bollatoScarti', {
      getStatsBollatoScarti: 'getStats',
    }),
    ...mapActions('movimentiScarti', {
      getStatsMovimentiScarti: 'getStats',
    }),
    ...mapActions('riconciliazione', {
      doReconciliation: 'doReconciliation',
      doExportReconciliation: 'doExportReconciliation',
    }),
    ...mapMutations('bollato', {
      resetBollato: 'RESET_CURRENT',
      resetStatsBollato: 'RESET_STATS_DATA',
      setFiltersBollato: 'SET_FILTERS',
      resetFiltersBollato: 'RESET_FILTERS',
    }),
    ...mapMutations('bollatoScarti', {
      resetStatsBollatoScarti: 'RESET_STATS_DATA',
      setFiltersBollatoScarti: 'SET_FILTERS',
      resetFiltersBollatoScarti: 'RESET_FILTERS',
    }),
    ...mapMutations('movimenti', {
      resetMovimenti: 'RESET_CURRENT',
      resetStatsMovimenti: 'RESET_STATS_DATA',
      setFiltersMovimenti: 'SET_FILTERS',
      resetFiltersMovimenti: 'RESET_FILTERS',
    }),
    ...mapMutations('movimentiScarti', {
      resetStatsMovimentiScarti: 'RESET_STATS_DATA',
      setFiltersMovimentiScarti: 'SET_FILTERS',
      resetFiltersMovimentiScarti: 'RESET_FILTERS',
    }),
    reset() {
      this.resetBollato()
    },
    async onSubmit() {
      const promises = []
      this.doingOnSubmit = true

      if (this.filesBollatoInAuto) {
        promises.push(
          this.filesBollatoInAuto.map(async (file) => {
            this.fileBollato = file
            this.typeBollato = `InAuto`
            return this.createBulkBollato()
          })
        )
      }
      if (this.filesBollatoDCSys) {
        promises.push(
          this.filesBollatoDCSys.map(async (file) => {
            this.fileBollato = file
            this.typeBollato = `DCSys`
            return this.createBulkBollato()
          })
        )
      }
      if (this.filesBollatoEMO) {
        promises.push(
          this.filesBollatoEMO.map(async (file) => {
            this.fileBollato = file
            this.typeBollato = `EMO`
            return this.createBulkBollato()
          })
        )
      }
      if (this.filesBollatoRcInt) {
        promises.push(
          this.filesBollatoRcInt.map(async (file) => {
            this.fileBollato = file
            this.typeBollato = `RcInt`
            return this.createBulkBollato()
          })
        )
      }

      if (this.filesMovimentiInbiz) {
        promises.push(
          this.filesMovimentiInbiz.map(async (file) => {
            this.fileMovimenti = file
            this.typeMovimenti = `Inbiz`
            await this.createBulkMovimenti()
          })
        )
      }
      if (this.filesMovimentiPoste) {
        promises.push(
          this.filesMovimentiPoste.map(async (file) => {
            this.fileMovimenti = file
            this.typeMovimenti = `Poste`
            await this.createBulkMovimenti()
          })
        )
      }
      this.reset()
      this.resetMovimenti()
      await Promise.all(promises.flat())

      this.uploadDone = true
      this.doingOnSubmit = false
      this.$emit('refresh')
      await this.getStats()
    },

    setFilters() {
      this.setFiltersBollato({ riconciliazione_id: this.riconciliazione_id })
      this.setFiltersMovimenti({ riconciliazione_id: this.riconciliazione_id })
      this.setFiltersMovimentiScarti({
        riconciliazione_id: this.riconciliazione_id,
        not_riconciliato: 1,
      })
      this.setFiltersBollatoScarti({
        riconciliazione_id: this.riconciliazione_id,
        not_riconciliato: 1,
      })
    },
    async onReset() {
      this.resetFiltersMovimenti()
      this.resetFiltersBollato()
      this.resetFiltersMovimentiScarti()
      this.resetFiltersBollatoScarti()

      this.setFilters()

      this.$emit('refresh')
      this.$emit('reset')
      this.uploadDone = false
      this.riconciliaDone = false
      this.$emit('reconciliationDone', false)
      await this.getStats()
    },

    async clickBtnRinconcilia() {
      try {
        this.doingRinconcilia = true
        await this.doReconciliation()
        this.$emit('refresh')
      } catch (err) {
        console.warn('clickBtnRinconcilia.Error', err)
      } finally {
        this.doingRinconcilia = false
        this.riconciliaDone = true
        this.$emit('reconciliationDone', true)
        await this.getStats()
      }
    },

    async clickBtnExportRiconciliazione() {
      try {
        this.doingExportRiconciliazione = true
        await this.doExportReconciliation()
      } catch (err) {
        console.warn('clickBtnExportRiconciliazione.Error', err)
      } finally {
        this.doingExportRiconciliazione = false
      }
    },

    async getStats() {
      return Promise.all([
        this.getStatsMovimenti(['stats']),
        this.getStatsBollato(['stats']),
        this.getStatsMovimentiScarti(['stats']),
        this.getStatsBollatoScarti(['stats']),
      ])
    },
    async resetStats() {
      return Promise.all([
        this.resetStatsMovimenti(),
        this.resetStatsBollato(),
        this.resetStatsMovimentiScarti(),
        this.resetStatsBollatoScarti(),
      ])
    },

  },
}
</script>

<style scoped></style>
